#Footer {
    font-size: var(--font-size-xsmall);
    font-family:var(--font-sans-secondary);
    margin-left:29%;
    padding: var(--padding-s) var(--padding-s) var(--padding-s) 0;
    color:var(--blue);
    
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    grid-template-rows:repeat(2,auto);
    grid-row-gap:var(--padding-s);
}

#Footer .logos {
    display:flex;
    justify-content:start;
    column-gap: var(--padding-s);

    &--bilbao {
        justify-content: flex-end;
        grid-row: 1 / span 1;
        align-items:start;
        .footer__logo {
            height:var(--height-logos);
            width: auto;
        }

    }
    &--movilitik {
        grid-row: 1 / span 1;

        .footer__logo {
            height:var(--height-logos);
            width: auto;
        }
    }
}

#Footer > nav {
    display: flex;
    align-items: center;

    a {
        @include font-sans(1.2);
        font-size: var(--font-size);
        color: inherit;
        margin: 0 1em;
    }
}

@media (max-width: $smartphone) {
  #Footer {
        /*--font-size: rem(12px);*/
        margin-left:0%;
        padding: var(--padding-s);

        grid-template-columns:repeat(1, 1fr);
        grid-template-rows:repeat(2, auto);
        grid-row-gap:var(--padding-m);
    }
  #Footer .logos {
    
        &--bilbao {
            padding-top:var(--padding-m);
            justify-content: flex-start;
            grid-row: 2 / span 1;
            grid-column: 1 / span 1;
            display:flex;
            flex-wrap:wrap;
            
            .footer__logo {
                margin:0 var(--padding-s) var(--padding-s) 0;
            }
        }
        &--movilitik {
            grid-row: 1 / span 1;
            grid-column: 1 / span 1;
        }
    }
}
