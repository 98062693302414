:root {
    --font-sans: 'NeueMontreal-Regular', sans-serif;
    --font-sans-secondary: 'SpaceMono-Regular', sans-serif;
    /*--font-sans-bold: 'SpaceMono-Regular', sans-serif;
    --font-sans-italic: 'FKGrotesk-ThinItalic', sans-serif;*/

    --font-size-xxlarge: #{(360px / 16px) * 1rem};
    --font-size-xlarge: #{(21px / 16px) * 1rem};
    --font-size-large: #{(21px / 16px) * 1rem};
    --font-size-mid: #{(21px / 16px) * 1rem};
    --font-size-base: #{(15px / 16px) * 1rem};
    --font-size-h2: #{(80px / 16px) * 1rem};
    --font-size-h3: #{(40px / 16px) * 1rem};
    --font-size-small: #{(13px / 16px) * 1rem};
    --font-size-xsmall: #{(10px / 16px) * 1rem};

    --line-height-sans: 1;
    --letter-spacing: -0.06em;

    font-size: 1.1111vw;

    @media (max-width: $smartphone) {
        font-size: 16px;
        --line-height-sans: 1.2;
    }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-secondary($line-height:0) {
  font-family: var(--font-sans);
  font-weight: normal;
  @if $line-height > 0 { line-height: $line-height  }
  @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-bold($line-height:0) {
  font-family: var(--font-sans-bold);
  font-weight: 500;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}
@mixin font-sans-italic($line-height:0) {
  font-family: var(--font-serif);
  font-weight: 200;
  font-style: italic;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}




