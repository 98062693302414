#Header {
    --width: 29%;
    --height: var(--header-height);
    --color: var(--yellow);
    --bg: var(--secondary);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-s);
    --font-size: var(--font-size-base);

    font-family: var(--font-sans);
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: 100vh;
    left: 0;
    top: 0;
    padding: var(--padding-s);

    display: flex;
    flex-direction:column;
    justify-content: space-between;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);

    transform: translate3d(0, var(--y-header), 10px);
}

#Header > .logo {
  width:100%;
  h1 {
    width:100%;
    padding-bottom:var(--padding-m);
  }
  svg {
    width:100%;
    height:auto;
  }
  .subtitle {
    display:flex;
    justify-content: space-between;
    column-gap: calc(var(--padding-s)*2);
    font-size: var(--font-size-small);
    margin-bottom: var(--padding-m);
  }
  .subtitle__intro {
    flex:0 0 45%;
  }
  .subtitle__dates {
    font-family:var(--font-sans-secondary);
    flex:0 0 55%;
  }
}


#Header > nav {
    display:flex;
    flex-direction:column;
    width:100%;

    a {
        position:relative;
        display:flex;
        align-content: center;
        width:101%;
        font-size:var(--font-size-mid);
        color: inherit;
        text-decoration:none;

        &[aria-current="page"] {
          pointer-events: none;
        }

        .download-arrow {
          width:var(--font-size-mid);
          margin-right:var(--padding-s);
          height:var(--font-size-mid);
        }
    }

    a::before {
      content:" ";
      display:inline-block;
      background-color:var(--yellow);

      opacity:1;
      transition: opacity 0.2s;

      width:var(--font-size-mid);
      height:var(--font-size-mid);
      
      position: absolute;
      top: 0;
      left:0;
    }

    a:hover {
      &::before {
        opacity:0;
      }
    }
    
    a.link {
      margin: var(--padding-xxs) 0 0 0;
    }

    
}


#Header .social {
  display: inline-flex;
  justify-content: space-between;
  flex-direction:row;
  margin-top:var(--padding-xs);

  color:var(--blue);
  a {
    width:fit-content;
    font-size:var(--font-size-xsmall);
    font-family:var(--font-sans-secondary);
  }
  a::before {
    display:none;
  }
  a:hover {
    text-decoration:underline;
  }
}

@media (max-width: $smartphone) {

  #Header {

    position:relative;
    --font-size: #{(16px / 16px) * 1rem};
    --width: 100%;

    height:100%;

    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;

    > .title {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    > .date {
      grid-column: 1 / 2;
      grid-row: 2 / 3;

      align-self: flex-end;
    }
  }

  #Header br {
    display:none;
  }

  #Header > nav {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    grid-template-columns: repeat(1, 1fr);

    text-align: left;

    a .download-arrow {
        margin-right:var(--padding-xxs);
    }
    a:not(:last-of-type) {
      margin-bottom: 0;
    }
    a::before {
      display:none;
    }

  }


  #Header > .logo {
    .subtitle {
      flex-direction:column-reverse;
      margin-bottom:var(--padding-s);
      font-size:var(--font-size-mid);
      .subtitle__dates {
        margin-bottom:var(--padding-s);
      }
    }
    .subtitle:last-child {
      margin-bottom:var(--padding-l);
    }
  }


  #Header .social {
    flex-direction:column;
    margin-top:var(--padding-s);
    a {
      font-size:var(--font-size-small);
    }
    a:active {
      text-decoration:underline;
    }
    a:not(:last-of-type) {
      margin-bottom: 1em;
    }
  }
}
