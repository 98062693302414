:root {
  --y-header: 0;

  --padding-xl: #{(100px / 16px) * 1rem};
  --padding-l: #{(60px / 16px) * 1rem};
  --padding-m: #{(30px / 16px) * 1rem};
  --padding-s: #{(20px / 16px) * 1rem};
  --padding-xs: #{(15px / 16px) * 1rem};
  --padding-xxs: #{(10px / 16px) * 1rem};

  --header-height:#{(77px / 16px) * 1rem};

  --height-logos: #{(32px / 16px) * 1rem};
}

@media (max-width: $smartphone) {
  :root {}
}
