.block-home {
    padding:var(--padding-s) var(--padding-s) var(--padding-s) 29%;
    width:100%;
    display:grid;
    grid-template-columns:repeat(2, 1fr);
    grid-gap: var(--padding-s);
}
.block-home .content {
    color:var(--yellow);
    font-family: var(--font-sans-secondary);
    font-size: var(--font-size-small);
    
    p {
        margin-bottom:var(--padding-s);
    }
    p.lan {
        margin-top:calc(var(--padding-s)*3);
    }
    a {
        color: white;
        text-decoration: underline;
        word-break: break-all;
    }

    &.--double {
        grid-column: 1 / span 2;
    }
}

.block-home figure {
    width:100%;
}

@media (max-width: $smartphone) {
    .block-home {
        padding:0 var(--padding-s) var(--padding-s) var(--padding-s);

        grid-template-columns:repeat(1, 1fr);
        grid-gap: 0;
        grid-row-gap: var(--padding-m);
    }
    .block-home .content {
        grid-column: 1 / span 1;
        p {
            margin-bottom:var(--padding-l);
            line-height:1.3;
        }
        p.lan {
            margin-top:var(--padding-s);
        }
    }
    .block-home .content-eus {
        margin-top:0;
        margin-bottom: var(--padding-m);
        p:last-child {
            margin-bottom:0;
        }

    }
    .block-home .content-es {
        grid-row: 7 / span 1;
        padding-top: var(--padding-l);
        border-top: 1px solid var(--yellow);
        p:last-child {
            margin-bottom:0;
        }
    }

}