:root {
  --white: #ffffff;
  --black: #000000;
  --yellow:#FBEA3A;
  --blue:#2FA0B2;
  --grey: #999;

  --primary: var(--yellow);
  --secondary: var(--black);

  --assertive: #ff00ff;
  --focus: #ff00ff;
  --color-error: #ff0000;
  --color-sucess: #4dc247;
}

.palette-primary {
    --primary: var(--black);
    --secondary: var(--grey);
}

.palette-black {
    --primary: var(--white);
    --secondary: var(--black);
}

.palette-red {
    --primary: var(--black);
    --secondary: var(--color-error);
}
